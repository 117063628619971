@font-face {
  font-family: 'OpenSansRegular';
  src: url('./assets/fonts/OpenSans-Regular.ttf');
}

body {
  background-color: #060606;
  font-family: 'OpenSansRegular';
  margin: 0;
  padding: 0;
  letter-spacing: -0.5px !important;
}

div {
  outline: none !important;
}

@media only screen and (max-width: 600px) {
  body {
    background-color: #060606;
    margin: 0;
    padding: 0;
  }

  .MuiFormLabel-root {
    font-size: 14px !important;
  }

  .MuiDialog-paper {
    margin: 10px !important;
  }

  .MuiDialogContent-root {
    padding: 8px 10px !important;
  }
}

.MuiAccordionSummary-root {
  color: #fff !important;
  font-family: 'Roboto' !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.Mui-expanded {
  color: #5CE157 !important;
}

.MuiPaper-root {
  background-color: transparent !important;
  border-bottom: 1px solid rgba(224, 224, 224, 0.2) !important;
}
.MuiMenu-paper {
  background-color: #fff !important;
}

.MuiTableCell-root {
  border-bottom: 1px solid rgba(224, 224, 224, 0.2) !important;
}

.MuiTableCell-head {
  color: #fff !important;
}
.MuiTableCell-body {
  color: #fff !important;
}

.Toastify__toast--error{
  background-color: #191C21 !important;
}

#videoBg {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
}

#inputContactPhone .MuiInput-underline:before {
  border-bottom: 0px solid white !important;
}
#inputContactPhone .MuiInput-underline:after {
  border-bottom: 0px solid white !important;
}

#inputContactPhone ::placeholder {
  color: #666 !important;
  opacity: 1;
  font-family: 'OpenSansRegular'; 
  font-size: 16px;
  min-height: 20px;
  width: 100%;
}

::placeholder {
  color: #666 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  color: #666 !important;
}

::-ms-input-placeholder {
  color: #666 !important;
}